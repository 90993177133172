/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { FleamarketSearchSuccessResponseDocumentsInnerComponentAds } from './fleamarket-search-success-response-documents-inner-component-ads';
import { IntegrateSearchSuccessResponseBusinessPost } from './integrate-search-success-response-business-post';
import { IntegrateSearchSuccessResponseCar } from './integrate-search-success-response-car';
import { IntegrateSearchSuccessResponseCommerce } from './integrate-search-success-response-commerce';
import { IntegrateSearchSuccessResponseCommunity } from './integrate-search-success-response-community';
import { IntegrateSearchSuccessResponseFleamarket } from './integrate-search-success-response-fleamarket';
import { IntegrateSearchSuccessResponseGroup } from './integrate-search-success-response-group';
import { IntegrateSearchSuccessResponseJob } from './integrate-search-success-response-job';
import { IntegrateSearchSuccessResponseLocalProfile } from './integrate-search-success-response-local-profile';
import { IntegrateSearchSuccessResponsePlace } from './integrate-search-success-response-place';
import { IntegrateSearchSuccessResponseRealty } from './integrate-search-success-response-realty';
import { V1Shortcut } from './v1-shortcut';

/**
 * 통합탭 검색 결과
 * @export
 * @interface IntegrateSearchSuccessResponse
 */
export interface IntegrateSearchSuccessResponse {
    [key: string]: any;

    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrateSearchSuccessResponse
     */
    'collectionRanks'?: Array<IntegrateSearchSuccessResponseCollectionRanksEnum>;
    /**
     * 
     * @type {IntegrateSearchSuccessResponseFleamarket}
     * @memberof IntegrateSearchSuccessResponse
     */
    'fleamarket'?: IntegrateSearchSuccessResponseFleamarket;
    /**
     * 
     * @type {IntegrateSearchSuccessResponseCommunity}
     * @memberof IntegrateSearchSuccessResponse
     */
    'community'?: IntegrateSearchSuccessResponseCommunity;
    /**
     * 
     * @type {IntegrateSearchSuccessResponseBusinessPost}
     * @memberof IntegrateSearchSuccessResponse
     */
    'businessPost'?: IntegrateSearchSuccessResponseBusinessPost;
    /**
     * 
     * @type {IntegrateSearchSuccessResponsePlace}
     * @memberof IntegrateSearchSuccessResponse
     */
    'place'?: IntegrateSearchSuccessResponsePlace;
    /**
     * 
     * @type {V1Shortcut}
     * @memberof IntegrateSearchSuccessResponse
     */
    'shortcut'?: V1Shortcut;
    /**
     * 
     * @type {IntegrateSearchSuccessResponseJob}
     * @memberof IntegrateSearchSuccessResponse
     */
    'job'?: IntegrateSearchSuccessResponseJob;
    /**
     * 
     * @type {FleamarketSearchSuccessResponseDocumentsInnerComponentAds}
     * @memberof IntegrateSearchSuccessResponse
     */
    'advertisement'?: FleamarketSearchSuccessResponseDocumentsInnerComponentAds;
    /**
     * 
     * @type {IntegrateSearchSuccessResponseCommerce}
     * @memberof IntegrateSearchSuccessResponse
     */
    'commerce'?: IntegrateSearchSuccessResponseCommerce;
    /**
     * 
     * @type {IntegrateSearchSuccessResponseCar}
     * @memberof IntegrateSearchSuccessResponse
     */
    'car'?: IntegrateSearchSuccessResponseCar;
    /**
     * 
     * @type {IntegrateSearchSuccessResponseRealty}
     * @memberof IntegrateSearchSuccessResponse
     */
    'realty'?: IntegrateSearchSuccessResponseRealty;
    /**
     * 
     * @type {IntegrateSearchSuccessResponseGroup}
     * @memberof IntegrateSearchSuccessResponse
     */
    'group'?: IntegrateSearchSuccessResponseGroup;
    /**
     * 
     * @type {IntegrateSearchSuccessResponseLocalProfile}
     * @memberof IntegrateSearchSuccessResponse
     */
    'localProfile'?: IntegrateSearchSuccessResponseLocalProfile;
    /**
     * 
     * @type {FleamarketSearchSuccessResponseDocumentsInnerComponentAds}
     * @memberof IntegrateSearchSuccessResponse
     */
    'dspAdvertisement'?: FleamarketSearchSuccessResponseDocumentsInnerComponentAds;
    /**
     * 
     * @type {FleamarketSearchSuccessResponseDocumentsInnerComponentAds}
     * @memberof IntegrateSearchSuccessResponse
     */
    'catalogProductAd'?: FleamarketSearchSuccessResponseDocumentsInnerComponentAds;
}

export const IntegrateSearchSuccessResponseCollectionRanksEnum = {
    UNSPECIFIED: 'COLLECTION_TYPE_UNSPECIFIED',
    FLEAMARKET: 'COLLECTION_TYPE_FLEAMARKET',
    COMMUNITY: 'COLLECTION_TYPE_COMMUNITY',
    BUSINESS_POSTS: 'COLLECTION_TYPE_BUSINESS_POSTS',
    PLACE: 'COLLECTION_TYPE_PLACE',
    SHORTCUT: 'COLLECTION_TYPE_SHORTCUT',
    JOB: 'COLLECTION_TYPE_JOB',
    ADVERTISEMENT: 'COLLECTION_TYPE_ADVERTISEMENT',
    COMMERCE: 'COLLECTION_TYPE_COMMERCE',
    CAR: 'COLLECTION_TYPE_CAR',
    REALTY: 'COLLECTION_TYPE_REALTY',
    GROUP: 'COLLECTION_TYPE_GROUP',
    LOCAL_PROFILE: 'COLLECTION_TYPE_LOCAL_PROFILE',
    DSP_ADVERTISEMENT: 'COLLECTION_TYPE_DSP_ADVERTISEMENT',
    CATALOG_PRODUCT_AD: 'COLLECTION_TYPE_CATALOG_PRODUCT_AD'
} as const;

export type IntegrateSearchSuccessResponseCollectionRanksEnum = typeof IntegrateSearchSuccessResponseCollectionRanksEnum[keyof typeof IntegrateSearchSuccessResponseCollectionRanksEnum];


