import type { QueryResolvers } from '../../__codegen__/__graphql__/resolvers'

export const ActiveRegionsQueries: QueryResolvers = {
  async activeRegions(parent, args, ctx) {
    const response = await ctx.services.activeRegions.getActiveRegions()

    if (!response) {
      console.error('FleaMarket ActiveRegions를 불러오는데 실패했습니다.')
      return {
        regions: [],
      }
    }

    return response
  },
}
