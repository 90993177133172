import type { QueryResolvers } from '../../__codegen__/__graphql__/resolvers'
import { HOME_TABS } from '../../_app/constants/homeTabs'
import type { HomeTabsType } from '../../_app/types/homeTabs'

export const AggregatedPreProcessQueries: QueryResolvers = {
  async aggregatedPreProcess(parent, args, ctx) {
    const resp = await ctx.services.aggregatedPreProcess.aggregatedPreProcess({
      query: args.data.query,
      regionId: args.data.regionId,
      referrer: {
        queryId: args.referrer.queryId,
        queryFromId: args.referrer.queryFromId,
        funnelFromId: args.referrer.funnelFromId,
        tab: args.referrer.tab,
        screenDepthName: args.referrer.screenDepthName,
        searchFunnelId: args.referrer.searchFunnelId,
        experiment: ctx.experiment,
      },
    })

    //기본값 처리
    if (!resp) {
      return {
        recommendTabInfo: {
          tabOrders: [
            HOME_TABS.AGGREGATED.ID,
            HOME_TABS.FLEA_MARKET.ID,
            HOME_TABS.COMMUNITY.ID,
            HOME_TABS.BIZ_ACCOUNT.ID,
            HOME_TABS.BUSINESS.ID,
            HOME_TABS.USERS.ID,
          ],
        },
        queryPreProcessInfo: {
          query: args.data.query,
          reformulationQuery: '',
          type: 'NONE',
        },
        experiment: {
          isExperimentTriggered: false,
          experimentSegments: [],
        },
      }
    }

    const tabIds = Object.values(HOME_TABS)
      .filter((tab) => tab.ID !== HOME_TABS.UNKNOWN.ID)
      .map((tab) => tab.ID)

    const tabOrders = resp.recommendTabInfo.tabOrders.filter(
      (tab: HomeTabsType) => (tabIds as string[]).includes(tab)
    )

    return {
      recommendTabInfo: {
        tabOrders: tabOrders,
      },
      queryPreProcessInfo: resp.queryPreProcessInfo,
    }
  },
}
