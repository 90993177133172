import { convertLocationToLocationParam } from '../../place/graphql'
import type { QueryResolvers } from '../../__codegen__/__graphql__/resolvers'

export const SearchAggregatedQueries: QueryResolvers = {
  async aggregatedAll(parent, args, ctx) {
    const resp = await ctx.services.aggregated.aggregatedAll({
      query: args.data.query,
      regionId: args.data.regionId,
      fleaMarket: args.data.fleaMarket
        ? {
            withoutCompleted: args.data.fleaMarket.withoutCompleted,
          }
        : undefined,
      useLocation: args.data.useLocation,
      location: convertLocationToLocationParam(args.data.location ?? undefined),
      referrer: {
        queryId: args.referrer.queryId,
        queryFromId: args.referrer.queryFromId,
        funnelFromId: args.referrer.funnelFromId,
        tab: args.referrer.tab,
        screenDepthName: args.referrer.screenDepthName,
        searchFunnelId: args.referrer.searchFunnelId,
        experiment: ctx.experiment,
      },
    })

    if (!resp) {
      throw new Error('Error: Failed to aggregated search result')
    }

    return {
      ...resp,
      error: resp.error,
    }
  },
}
