import type {
  ActiveRegionResolvers,
  ActiveRegionsResolvers,
} from '../../__codegen__/__graphql__/resolvers'

export const ActiveRegions: ActiveRegionsResolvers = {
  regions(parent) {
    return parent.regions ?? []
  },
}

export const ActiveRegion: ActiveRegionResolvers = {
  id(parent) {
    return `ActiveRegion#${parent.id}`
  },
  _id(parent) {
    return parent.id
  },
  order(parent) {
    return parent.order
  },
  displayName(parent) {
    return parent.displayName
  },
  centerCoordinates(parent) {
    return parent.centerCoordinates ?? null
  },
}
