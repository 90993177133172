import { take } from 'lodash-es'
import type { QueryResolvers } from '../../__codegen__/__graphql__/resolvers'

export const SearchUserQueries: QueryResolvers = {
  /**
   * 유저 검색 결과
   */
  async searchUsers(parent, args, ctx) {
    const first = args.first + 1

    const resp = await ctx.services.user.getUsers({
      query: args.data.query,
      regionId: args.data.regionId.toString(),
      pageSize: first,
      pageToken: args.after ?? undefined,
      referrer: {
        queryId: args.referrer.queryId,
        queryFromId: args.referrer.queryFromId,
        funnelFromId: args.referrer.funnelFromId,
        tab: args.referrer.tab,
        screenDepthName: args.referrer.screenDepthName,
        searchFunnelId: args.referrer.searchFunnelId,
        experiment: ctx.experiment,
      },
    })

    if (!resp) {
      throw new Error('Failed to user search result')
    }
    return {
      edges: take(resp.connection.edges, args.first),
      pageInfo: {
        hasNextPage: resp.connection.edges.length > args.first,
        hasPreviousPage: false,
        startCursor: resp.connection.edges[0]?.cursor ?? null,
        endCursor: resp.connection.edges[args.first - 1]?.cursor ?? null,
      },
      error: resp.error,
    }
  },
}
