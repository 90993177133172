import type { StateCreatorWithDevtools } from '../../__development/zustand'
import type { RegionRangeType } from '../types'

export const FLEA_MARKET_REGION_RANGE: Record<
  RegionRangeType,
  {
    ID: RegionRangeType
    LABEL: string
    // NOTE: 로깅할 때 사용
    ORDER: number
  }
> = {
  MY: {
    ID: 'MY',
    LABEL: '가까운 동네',
    ORDER: 2, // (서버) karrotMap에서 2부터 시작
  },
  ADJACENT: {
    ID: 'ADJACENT',
    LABEL: '조금 가까운 동네',
    ORDER: 3,
  },
  RANGE_2: {
    ID: 'RANGE_2',
    LABEL: '조금 먼 동네',
    ORDER: 4,
  },
  RANGE_3: {
    ID: 'RANGE_3',
    LABEL: '먼 동네',
    ORDER: 5,
  },
}

export interface RegionRangeState {
  type: RegionRangeType
  count: number
}

export const DEFAULT_REGION_RANGE_TYPE = FLEA_MARKET_REGION_RANGE.RANGE_3.ID
export const DEFAULT_REGION_RANGE_COUNT = 0

export type FleaMarketFilterRegionSliceType = {
  fleaMarketFilterRegionRangeState: {
    type: RegionRangeType
    // NOTE: 슬라이더 value
    count: number

    changeRegionRange: (regionRangeState: RegionRangeState) => void
  }
}

export const fleaMarketFilterRegionRangeSlice: StateCreatorWithDevtools<
  FleaMarketFilterRegionSliceType
> = (set) => ({
  fleaMarketFilterRegionRangeState: {
    type: DEFAULT_REGION_RANGE_TYPE,
    count: DEFAULT_REGION_RANGE_COUNT,

    changeRegionRange: (regionRangeState: RegionRangeState) =>
      set((state) => ({
        fleaMarketFilterRegionRangeState: {
          ...state.fleaMarketFilterRegionRangeState,
          type: regionRangeState.type,
          count: regionRangeState.count,
        },
      })),
  },
})

export const isDefaultRegionRange = (rangeType: RegionRangeType) => {
  return rangeType === FLEA_MARKET_REGION_RANGE.RANGE_3.ID
}
