export const SCREEN_DEPTH_NAMES = {
  DISCOVERY: 'DISCOVERY',
  RESULT: 'RESULT',
  MAP: 'MAP',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
} as const

export const QUERY_FROM = {
  UNKNOWN: {
    ID: 'UNKNOWN', // Native나 Query를 통해 받은 데이터
    LABEL: 'UNKNOWN',
    VALUE: 'unknown', // Firebase, 당근 SDK, 서버 등 행동데이터(로그)를 남기는 데이터
  },
  TYPED: {
    ID: 'TYPED',
    LABEL: '직접입력',
    VALUE: 'typed',
  },
  HOT: {
    ID: 'HOT',
    LABEL: '인기/추천',
    VALUE: 'hot',
  },
  RECENT: {
    ID: 'RECENT',
    LABEL: '최근검색',
    VALUE: 'recent',
  },
  SUGGESTED: {
    ID: 'SUGGESTED',
    LABEL: '자동완성검색',
    VALUE: 'suggested',
  },
  RECOMMENDED_KEYWORD: {
    ID: 'RECOMMENDED_KEYWORD',
    LABEL: '추천검색어',
    VALUE: 'recommended_keyword',
  },
  ORIGINAL_KEYWORD: {
    ID: 'ORIGINAL_KEYWORD',
    LABEL: '오타교정전 검색어',
    VALUE: 'original_keyword',
  },
  SCHEME: {
    ID: 'SCHEME',
    LABEL: '스킴',
    VALUE: 'scheme',
  },
} as const

export type QueryFromIdTypes =
  (typeof QUERY_FROM)[keyof typeof QUERY_FROM]['ID']

export const FUNNEL_FROM = {
  UNKNOWN: {
    ID: 'UNKNOWN',
    LABEL: 'UNKNOWN',
    VALUE: 'unknown',
  },
  HOME: {
    ID: 'HOME',
    LABEL: '홈탭',
    VALUE: 'home',
  },
  HOME_FEED: {
    ID: 'HOME_FEED',
    LABEL: '홈 피드',
    VALUE: 'home_feed',
  },
  NEARBY: {
    ID: 'NEARBY',
    LABEL: '내근처탭',
    VALUE: 'nearby',
  },
  STORY: {
    // 레거시 사용 X
    ID: 'STORY',
    LABEL: '동네생활탭',
    VALUE: 'community',
  },
  COMMUNITY: {
    ID: 'COMMUNITY',
    LABEL: '동네생활탭',
    VALUE: 'community',
  },
  FLEA_MARKET_CATEGORY: {
    ID: 'FLEA_MARKET_CATEGORY',
    LABEL: '중고 카테고리 검색',
    VALUE: 'fleamarket-category',
  },
  CURATION_PAGE: {
    ID: 'CURATION_PAGE',
    LABEL: '이벤트성 페이지',
    VALUE: 'curation-page',
  },
  FLEAMARKET_ARTICLE_CONTEXT: {
    ID: 'FLEAMARKET_ARTICLE_CONTEXT',
    LABEL: '중고거래 상세 페이지 컨텍스트',
    VALUE: 'fleamarket_article_context',
  },
  COMMUNITY_ARTICLE_CONTEXT: {
    ID: 'COMMUNITY_ARTICLE_CONTEXT',
    LABEL: '동네생활 상세 페이지 컨텍스트',
    VALUE: 'community_article_context',
  },
} as const

export type FunnelFromIdTypes =
  (typeof FUNNEL_FROM)[keyof typeof FUNNEL_FROM]['ID']

export const FUNNEL_FROM_IDS = Object.values(FUNNEL_FROM).map((ITEM) => ITEM.ID)

export const FILTER_TYPE = {
  REGION_DEPTH: 'region_depth',
  PRICE: 'price',
  CATEGORY: 'category',
  SORT: 'sort',
} as const
type FilterType = (typeof FILTER_TYPE)[keyof typeof FILTER_TYPE]

export const SORT_TYPE = {
  RECENT: 'recent',
  RELEVANT: 'relevant',
  DISTANCE: 'distance',
} as const

type SortType = (typeof SORT_TYPE)[keyof typeof SORT_TYPE]

export const SORT_RECOMMENDED_TYPE = {
  RECENT: 'recent',
  RELEVANT: 'relevant',
} as const

export type SortRecommendedType =
  (typeof SORT_RECOMMENDED_TYPE)[keyof typeof SORT_RECOMMENDED_TYPE]

export type FilterParamType = {
  filterType: FilterType
  filterRegionDepth: number | null
  filterCategoryIds: number[] | null
  filterSort: SortType | null
  filterPriceGte: number | null
  filterPriceLte: number | null
}

export type FilterRecommendedType = {
  filterType: FilterType
  regionDepth: number | null
  categoryId: number | null
  sort: SortRecommendedType | null
  priceGte: number | null
  priceLte: number | null
  isRecommend: number | null
}

/**
 * https://www.notion.so/daangn/view_type-e4c755ed70004916a1e5fdee0824d055
 */
export const CONTAINER_VIEW_TYPE = {
  /**
   * 싱글 리스트뷰
   */
  SINGLE_VIEW: 'single_view',
  /**
   * 배너 형태
   */
  BANNER_VIEW: 'banner_view',
  /**
   * 리스트가 묶여서 그룹인 뷰
   */
  GROUP_VIEW: 'group_view',
  /**
   * 캐러셀(가로스크롤) 뷰
   */
  CAROUSEL_VIEW: 'carousel_view',
  // TODO: 동네생활 업체 추천 실험을 위한 임시 뷰타입이예요. 실험 종료 후 제거 필요
  CROSS_COLLECTION_VIEW: 'cross_collection_view',
  NULL: null,
} as const

export type ContainerViewTypes =
  (typeof CONTAINER_VIEW_TYPE)[keyof typeof CONTAINER_VIEW_TYPE]

export const FEATURE_TYPE = {
  SEARCH_RESULT: 'search_result',
  RECOMMENDED_KEYWORD: 'recommended_keyword',
  SHORTCUT: 'shortcut',
}

export type FeatureType = (typeof FEATURE_TYPE)[keyof typeof FEATURE_TYPE]

export const KEYWORD_NOTIFICATION_BUTTON_TYPE = {
  FLOATING_ACTION_BUTTON: 'floating_action_button',
  BOX_BUTTON: 'box_button',
  BOX_BUTTON_NR: 'box_button_nr',
} as const

export type KeywordNotificationButtonTypes =
  (typeof KEYWORD_NOTIFICATION_BUTTON_TYPE)[keyof typeof KEYWORD_NOTIFICATION_BUTTON_TYPE]
