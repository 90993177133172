import to from 'await-to-js'
import axios from 'axios'
import axiosRetry from 'axios-retry'

import {
  IS_ENV_SSR,
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_FRONT_SERVER_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeUserAgentPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeCommonHeadersPlugin,
  plantaeSearchOriginPlugin,
  plantaeKarrotUserIdPlugin,
  plantaeSearchWebVersionPlugin,
  plantaeInjectServerSideHeaderPlugin,
} from '../../plantae/plugins'
import { ActiveRegionsApi } from '../../__codegen__/__openapi__/search-front-server'
import type { NodeOnlySSRBypassHeadersType } from '../../_app/context/NodeOnlySSRBypassHeaderProvider'

export type ServiceActiveRegionsType = ReturnType<typeof ServiceActiveRegions>

export function getServiceActiveRegions({
  app,
  user,
  __NODE_ONLY_bypassHeaders,
}: {
  app: AppType
  user: UserType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) {
  return ServiceActiveRegions({
    baseUrl: SEARCH_FRONT_SERVER_ENDPOINT,
    app,
    user,
    __NODE_ONLY_bypassHeaders,
  })
}

const ServiceActiveRegions = ({
  baseUrl,
  app,
  user,
  __NODE_ONLY_bypassHeaders,
}: {
  baseUrl: string
  app: AppType
  user: UserType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }
  const axiosInstance = axios.create({
    headers,
    timeout: NETWORK_TIMEOUT,
  })

  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: IS_ENV_SSR
      ? [plantaeInjectServerSideHeaderPlugin({ __NODE_ONLY_bypassHeaders })]
      : [
          plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
          plantaeRequestIdPlugin(),
          plantaeKarrotSessionIdPlugin({ app }),
          plantaeUserAgentPlugin({ userAgent: app.userAgent }),
          plantaeKarrotUserIdPlugin({ app, userId: user.id }),
          plantaeCommonHeadersPlugin(),
          plantaeSearchOriginPlugin(),
          plantaeSearchWebVersionPlugin(),
        ],
  })

  axiosRetry(axiosInstance, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
    shouldResetTimeout: true,
  })

  const client = new ActiveRegionsApi(undefined, baseUrl, axiosInstance)

  return {
    /**
     * 내 동네 설정에서 유저가 설정한 두 개 지역 조회중고거래 필터, 지역 범위
     */
    async getActiveRegions() {
      const [error, resp] = await to(client.apiV1ActiveRegionsGet())

      if (error || !resp?.data) {
        captureException(error)
        return null
      }

      if ('status' in resp.data) {
        return null
      }

      return resp.data
    },
  }
}
