/**
 * 스테이지
 */
export const STAGE = (() => {
  if (globalThis.__KARROT_SEARCH_INJECTED_CONFIG__?.STAGE) {
    return globalThis.__KARROT_SEARCH_INJECTED_CONFIG__.STAGE
  }

  if (process.env.REACT_APP_STAGE) {
    return process.env.REACT_APP_STAGE as
      | 'production' // 프로덕션 앱
      | 'alpha' // 알파 앱
      | 'development' // 로컬 개발 환경
      | 'analyze' // 빌드 정적 분석
  }

  return 'production'
})()

export const IS_ENV_SSR = (() => {
  return process.env.IS_RUNTIME === 'NODE'
})()

/**
 * 프리뷰 ID
 */
export const PREVIEW_DEPLOYMENT_ID = (() => {
  if (IS_ENV_SSR) {
    return null
  }
  if (!window.__KARROT_SEARCH_INJECTED_CONFIG__?.PREVIEW_DEPLOYMENT_ID) {
    return null
  }

  const previewDeploymentId = parseInt(
    window.__KARROT_SEARCH_INJECTED_CONFIG__.PREVIEW_DEPLOYMENT_ID,
    10
  )
  if (Number.isNaN(previewDeploymentId)) {
    return null
  }

  return previewDeploymentId
})()

/**
 * 커밋 Hash
 */
export const VERSION = process.env.VERSION as string

/**
 * Sentry DSN
 */
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string

/**
 * DataDog Application Id
 */
export const DATADOG_APPLICATION_ID = process.env
  .REACT_APP_DATADOG_APPLICATION_ID as string

/**
 * DataDog Client Token
 */
export const DATADOG_CLIENT_TOKEN = process.env
  .REACT_APP_DATADOG_CLIENT_TOKEN as string

/**
 * @deprecated
 * 검색 서비스 엔드포인트(레거시)
 */
export const SEARCH_ENDPOINT_LEGACY = (() => {
  if (STAGE === 'alpha' || STAGE === 'development') {
    return 'https://search-server.alpha.kr.karrotmarket.com'
  }

  return 'https://search-server.kr.karrotmarket.com'
})()

/**
 * 검색 서비스 엔드포인트(v4:grpc-gateway)
 */
export const SEARCH_ENDPOINT = (() => {
  if (STAGE === 'alpha' || STAGE === 'development') {
    return 'https://search-server-gw.alpha.kr.karrotmarket.com'
  }

  return 'https://search-server-gw.kr.karrotmarket.com'
})()

/**
 * 검색 웹뷰 BFF 엔드포인트
 */
export const SEARCH_FRONT_SERVER_ENDPOINT = (() => {
  if (STAGE === 'development') {
    return 'http://localhost:8080'
  }

  if (IS_ENV_SSR) {
    if (STAGE === 'alpha') {
      return 'http://search-front-internal.alpha.kr.krmt.io'
    }

    return 'http://search-front-internal.kr.krmt.io'
  }

  if (STAGE === 'alpha') {
    return PREVIEW_DEPLOYMENT_ID !== null
      ? `https://preview-${PREVIEW_DEPLOYMENT_ID}-search-front.alpha.kr.karrotmarket.com`
      : 'https://search.alpha.karrotwebview.com'
  }

  return 'https://search.karrotwebview.com'
})()

/**
 * 비즈 프로필 웹뷰 앱
 */
export const BIZ_PLATFORM_APP =
  STAGE === 'production'
    ? 'https://bizprofile.karrotwebview.com'
    : 'https://bizprofile.alpha.karrotwebview.com'

/**
 * 동네 알바 웹뷰 앱
 */
export const JOBS_APP =
  STAGE === 'production'
    ? 'https://jobs.karrotwebview.com'
    : 'https://jobs.alpha.karrotwebview.com'

/**
 * 동네 모임 웹뷰 앱
 */
export const KARROT_GROUP_APP =
  STAGE === 'production'
    ? 'https://community-web-group.karrotwebview.com'
    : 'https://community-web-group.alpha.karrotwebview.com'

/**
 * 업체 상품 웹뷰 앱
 *
 * 더보기: /search,
 * 게시글: /products
 */
export const COMMERCE_APP =
  STAGE === 'production'
    ? 'https://commerce.karrotwebview.com'
    : 'https://commerce.alpha.karrotwebview.com'

/**
 * 중고차 직거래 웹뷰 앱
 *
 * 더보기(홈): /
 * 더보기(검색결과): /search
 * 게시글: /articles
 *
 */
export const CAR_APP =
  STAGE === 'production'
    ? 'https://car.karrotwebview.com'
    : 'https://car.alpha.karrotwebview.com'

export const COMMUNITY_AGORA_APP =
  STAGE === 'production'
    ? 'https://community-web-agora.kr.karrotmarket.com'
    : 'https://community-web-agora.alpha.kr.karrotmarket.com'

/**
 * 검색 로컬 스토리지에 PersistedState JSON을 저장할 키
 */
export const SEARCH_LOCAL_STORAGE_STATE_KEY = `search/${STAGE}/v1/state`

/**
 * 검색 세션 스토리지에 filter JSON을 저장할 키
 */
export const SEARCH_FILTER_SESSION_STORAGE_STATE_KEY = `search/${STAGE}/filterState`

/**
 * 기본 검색 결과 썸네일
 */
export const THUMBNAIL_FALLBACK =
  'https://assetstorage.krrt.io/1149181061857716525/4df689c7-797d-4037-b5f5-9a3aa2be5c46/width=560,height=560.png'

/**
 * 로컬 스토리지에 (Code Split) Chunk File 로드 실패 시 Flag를 저장할 키
 */
export const LOCAL_STORAGE_REFRESH_CHUNK_LOAD_FAILED_FLAG_KEY = `search/${STAGE}/v1/refresh_chunk_load_failed_flag`

/**
 * (Staging Only) 검색 로컬 스토리지에 foundation override JSON을 저장할 키
 */
export const SEARCH_LOCAL_STORAGE_FOUNDATION_OVERRIDE_KEY = `search/${STAGE}/v1/foundationOverride`

/**
 * (Search Admin Preview Only) 검색 쿠키에서 foundation override JSON을 저장할 키
 */
export const SEARCH_COOKIE_STORAGE_FOUNDATION_OVERRIDE_KEY = (() => {
  const stage = STAGE === 'production' ? 'production' : 'alpha'

  return `search-${stage}-foundationOverride`
})()

/**
 * 네트워크 재시도 숫자
 */
export const RETRY_COUNT = 2

/**
 * 네트워크 타임아웃(10초)
 */
export const NETWORK_TIMEOUT = 10_000
